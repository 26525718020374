// https://cloudinary.com/documentation/resizing_and_cropping
// example: https://res.cloudinary.com/dipqm0n1i/image/upload/c_fill,g_auto,h_48,w_48,f_auto/EtihadAirways/1696506804232-etihad.png
// Important: Your account has been cancelled. Contact support for assistance.
// export const cloudinaryURL =
//   "https://res.cloudinary.com/dipqm0n1i/image/upload/EtihadAirways/";

// Env: dev
// export const cloudinaryURL =
//   "https://etihadairwaysassets143308-dev.s3.us-east-2.amazonaws.com/public/";
// export const s3_key = `https://etihadairwaysassets143308-dev.s3.us-east-2.amazonaws.com/public/`;

// Env: Prod
export const cloudinaryURL =
  "https://etihadairwaysassetsd26b2-prod.s3.us-east-2.amazonaws.com/public/";
export const s3_key = `https://etihadairwaysassetsd26b2-prod.s3.us-east-2.amazonaws.com/public/`;

export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "English",
  ARABIC = "Arabic",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA_LIBRARY = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "questions",
  QUESTIONS_TYPES = "questions-types",
  GROUPS = "groups",
  GROUP_SETTINGS = "group-settings",
  INTERESTS = "interests",
  BOOKINGS = "bookings",
  BOOKINGS_LIST = "bookings-history",
  FLAGS = "flags",
  GUESTS = "guests",
  GUESTS_PROFILE = "guests-profile",
  CONCEPTS = "concepts",
  PAYMOB = "paymob",
  BRANCHES = "branches",
  RESERVATION_STATUS = "statuses",
  TIME_SLOTS = "time-slots",
  AREAS = "areas",
  COMMENTS = "comments",
  PLAN_ITEMS = "plan-items",
  TABLES = "tables",
  TIMELINES = "timelines",
  SERVING_AREAS = "serving-areas",
  OBJECTS = "objects",
  CUSTOMER_STATS = "customerStats",
  FLOOR_EDITOR = "floor-editor",
  SOCIAL_LINKS = "social-links",
  CALL_CENTER = "call-center",
  CALL_CENTER_SETTINGS = "call-center-settings",
  BOOKINGS_TABLES = "bookings-tables",
  RESET_PASSWORD = "reset-password",
  NOTIFICATION_SETTINGS = "notification-settings",
  NOTIFICATIONS = "notifications",
  Mobile_NOTIFICATIONS = "mobileNotifications",
  BOOKING_CONFIRMATION = "reservations",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  PAYMENT = "Payment",
  PAYMENT_DASHBOARD = "transactionsDashboard",
  TRANSACTIONS_REPORT = "transactions",
  PAYMENT_STATS = "paymentStats",
  BOOKING_GUEST = "bookingGuests",
  Parent_Concepts = "parentconcepts",
  // Online Ordering
  MENU_ROTATIONS = "menuRotations",
  CATEGORIES = "categories",
  MENU_ITEMS = "menuItems",
  PRICES = "prices",
  CHOICES = "choices",
  CHOICE_GROUPS = "choiceGroups",
  ORDER_STATUSES = "orderStatuses",
  ONLINE_ORDER = "onlineOrders",
  KPI_REPORT = "KPIReport",
  DISPATCHERS = "dispatchers",
  STAFF_MEMBERS = "staffMembers",
  WAITER_ORDER = "waiterOrder",
  MAIN_CATEGORIES = "mainCategories",
  SUB_CATEGORIES = "subCategories",
  SHIFTS = "shifts",
  PREPARATION_AREA = "preparationAreas",
  Analysis = "analysis",
  WAITERS_REPORT = "waitersReport",
  MENU_ITEMS_REPORT = "menuItemsReport",
  SERVING_AREA_REPORT = "servingAreaReport",
  WAREHOUSE = "warehouse",
}

export enum Reports {
  WAITER_PERFORMANCE = "Waiter Performance",
  MENU_ITEM_CONSUMPTION = "Menu Item Consumption",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
  DELETE = "Delete",
  LIST = "List",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum TimelineActions {
  CHANGE_FACEBOOK = "Facebook link",
  CHANGE_INSTAGRAM = "Instagram link",
  CHANGE_NAME = "name",
  CHANGE_ADDRESS = "address",
  CHANGE_GROUP = "Group",
  CHANGE_EMAIL = "Email",
  CHANGE_VERIFICATION_STATUS = "Verify Guest",
  ADD_INTEREST = "Add Customer Interests",
  REMOVE_INTEREST = "Remove Customer Interests",
  ADD_FLAG = "Add Customer Flags",
  REMOVE_FLAG = "Remove Customer Flags",
  CHANGE_PHONE_NUMBER = "Phone Number",
  DEPOSIT_PAYMENT_SUCCESS = "Deposit Payment Success",
  DEPOSIT_PAYMENT_FAILED = "Deposit Payment Failed",
  DEPOSIT_PAYMENT_REFUND = "Deposit Refund Success",
  SHIFT_CHANGE = "Shift Change",
  SHIFT_ASSIGNED = "Shift Assigned",
  ADMIN_GROUP_ADDED = "Admin Group Added",
  ADMIN_GROUP_REMOVED = "Admin Group Removed",
  PREPARATION_AREA_ADDED = "Service Area Added",
  PREPARATION_AREA_REMOVED = "Service Area Removed",
  CONCEPT_ADDED = "Concept Added",
  CONCEPT_REMOVED = "Concept Removed",
  USER_ATTRIBUTES_CHANGED = "User Attributes",
  CHANGE_PASSWORD = "Password",
  USER_ENABELED = "User Enabled",
  USER_DISABLED = "User Disabled",
}

export enum Channels {
  TELEPHONE_CHANNEL = "telephone",
  ANDROID_CHANNEL = "android",
  IPHONE_CHANNEL = "iphone",
  WEB_CHANNEL = "web",
  WEBSITE = "Website",
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  WHATSAPP = "Whatsapp",
}

export enum ResourceTypes {
  CUSTOMER = "customer",
  BOOKING = "booking",
}

export enum BookingTimelineActions {
  TIME_SLOT = "Update time slot",
  STATUS = "Update Booking Status",
  TABLE = "Update Booking Table",
  DATE = "Update Booking Date",
  GUEST_COUNT = "Update Guest Count",
  RESERVATION_EMAIL = "Send Reservation Email",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  ADMIN = "admin",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
  ADMIN_DELETE_USER = "/adminDeleteUser",
}

export enum StatuesIds {
  CANCELLED = "151a9e09-5d09-4f07-9928-90cccf46f41d",
  CLOSED = "1ca36387-69d5-4dba-be42-634b34493729",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  ADMINS = "admins_access",
  VIEW_RESERVATIONS = "view_reservation_access",
  CREATE_RESERVATION = "create_reservation_access",
  CANCEL_RESERVATION = "cancel_reservation_access",
  CREATE_GUEST = "create_guest_access",
  EDIT_GUEST = "edit_guest_access",
  EDIT_VIP = "edit_vip_access",
  LOCK_TABLE = "lock_table_access",
  EDIT_LAYOUT = "edit_layout_access",
  TRANSACTION_REPORT = "transaction_report",
  PAYMENT_DASHBOARD = "payment_dashboard",
  PAYMOB_SETTINGS = "paymob_settings",
  CALL_CENTER = "call_center",
  WAITER = "waiter",
  KITCHEN = "kitchen",
  REPORTS = "reports",
  CANCEL_ORDER = "cancel_order",
}

export enum LOCAL_STORAGE {
  SYNC_BOOKINGS = "sync-bookings",
  SYNC_GUESTS = "sync-guests",
  SYNC_GUEST_STATS = "sync-guest-stats",
  SELECTED_CONCEPT = "selected-concept",
  BOOKING_DATE_RANGE = "booking-date-range",
  BOOKING_GUEST = "booking-guest",
  BOOKING_REFRESH = "booking-refresh",
  BOOKING_SYNC_DURATION = "booking-sync-duration",
  BOOKING_DATE = "booking-date",
  GUESTS_SEARCH = "guests-search",
}

export enum DATE_PICKER_TYPES {
  SINGLE = "single",
  RANGE = "range",
}

export enum MODAL_TYPES {
  DATE_PICKER = "date-picker",
  BOOKING = "booking",
  EDIT_BOOKING = "edit-booking",
  VIEW_OLD_BOOKING = "view-old-booking",
  GUEST_STATS = "guest-stats",
}

export enum Transaction_TYPES {
  DEPOSIT = "deposit",
  REFUND = "refund",
}

export enum BOOKING_STATUS {
  CANCELLED = "Cancelled",
  CONFIRMED = "Confirmed",
  CLOSED = "Closed",
  WAITING_LIST = "Waiting List",
  PENDING_REVIEW = "Pending Review",
}

export enum OrderStatus {
  pending = "pending",
  sent = "sent to restaurant",
  failed = "failed to sent to restaurant",
  confirmed = "confirmed",
  cancelled = "cancelled",
  pendingCancellation = "pending Cancellation",
  updated = "updated",
  acceptedByWaiter = "accepted by waiter",
  sentToKitchen = "sent To Kitchen",
  acceptedByKitchen = "accepted by kitchen",
  ready = "ready",
  childrenReady = "all ready",
  outForDelivery = "out for delivery",
  delivered = "delivered",

  // Print Status
  printingFailed = "printing failed",
  waitingPickUp = "waiting pick up",
}

export enum WaiterActions {
  acceptOrder = "acceptOrder",
  acceptCall = "acceptCall",
}

export const OrderStatusList = [
  { name: OrderStatus.pending, value: OrderStatus.pending },
  { name: OrderStatus.updated, value: OrderStatus.updated },
  { name: OrderStatus.acceptedByWaiter, value: OrderStatus.acceptedByWaiter },
  { name: OrderStatus.sentToKitchen, value: OrderStatus.sentToKitchen },
  { name: OrderStatus.delivered, value: OrderStatus.delivered },
];
export const allOrderStatus = [
  OrderStatus.pending,
  OrderStatus.updated,
  OrderStatus.acceptedByWaiter,
  OrderStatus.sentToKitchen,
  OrderStatus.delivered,
];

export enum Website {
  ETIHAD = "https://etihad.anyware.software",
}

export enum AdminType {
  WAITER = "waiter",
  CALLCENTER = "callCenter",
}

export enum CallWaiterStatus {
  pending = "pending",
  accepted = "accepted",
  arrived = "arrived",
}

export enum ReportType {
  WAITER = "waiter",
  MENUITEM = "menu",
  SERVING_AREA = "servingArea",
}

export enum AccountDomain {
  ETIHAD_DOMAIN = "etihad",
}
