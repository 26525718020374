import {
  CreateMenuRotationInput,
  ModelSortDirection,
  UpdateMenuRotationInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { MenuRotation } from "../models";
import { createMenuRotation, updateMenuRotation } from "../graphql/mutations";
import { getMenuRotation, listMenuRotations } from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import { getDateFormatted } from "../helpers/utils";
import { onCreateMenuRotation } from "../graphql/subscriptions";
import { Languages } from "../constants/enums";
import { getTimeInUAE } from "../helpers/utils";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setFilters,
  setSelectedFilters,
  setNextToken,
  setPagination,
  setSelected,
} from "../store/ducks/menuRotation";

const useMenuRotation = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);

  const menuRotationsAllListing = useSelector(
    (state: any) => state.menuRotations.allListing
  );

  let paginationListing = useSelector(
    (state: any) => state.menuRotations.pagination
  );

  // Fetch with pagination
  async function fetch(params: ListingByConceptVariables) {
    const { limit } = params;

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      const rotationsListing: any = await API.graphql({
        query: listMenuRotations,
        variables: {
          filter,
          limit: limit || 1000,
          ModelSortDirection: ModelSortDirection.ASC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken =
        rotationsListing.data.listMenuRotations.nextToken;
      const listing: any = rotationsListing.data.listMenuRotations.items;

      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err) {
      showError(err);
    }
  }

  async function getActiveMenuRotation(params: ListingByConceptVariables) {
    const { limit } = params;

    try {
      const currentDate = new Date().toISOString().split("T")[0]; // Format: yyyy-mm-dd

      const filter: any = {
        deleted: { eq: "0" },
        startDate: {
          le: currentDate,
        },
        endDate: {
          ge: currentDate,
        },
      };

      const rotationsListing: any = await API.graphql({
        query: listMenuRotations,
        variables: {
          filter,
          limit: limit || 1000,
          ModelSortDirection: ModelSortDirection.ASC,
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      const currentNextToken =
        rotationsListing.data.listMenuRotations.nextToken;
      const listing: any = rotationsListing.data.listMenuRotations.items;

      dispatch(setNextToken(currentNextToken));

      let activeMenuRotation =
        listing && listing.length > 0 ? listing[0] : null;

      return activeMenuRotation;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const menuRotation: any = await API.graphql({
        query: getMenuRotation,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return menuRotation.data.getMenuRotation;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateMenuRotationInput = {
        conceptID: data.conceptID,

        name: data.name.toLowerCase().trim(),
        multiLanguages: multiLanguages,
        precedence: data.precedence ? data.precedence : "0",

        startDate: data.startDate ? data.startDate : null,
        endDate: data.endDate ? data.endDate : null,

        createdAt: getDateFormatted(getTimeInUAE()),
        createdByID: userID,
        createdByName: userName,
      };

      const menuRotation = await API.graphql<GraphQLQuery<MenuRotation>>({
        query: createMenuRotation,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // Add it to list
      dispatch(setListing([...menuRotationsAllListing, menuRotation]));

      showConfirm(`New ${singleName} has been created successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      return menuRotation;
    } catch (err) {
      console.log(err);
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        if (data.subName) {
          multiLanguages.set(`${language}-subName`, data.subName);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        if (data.subName) {
          multiLanguages.push([`${language}-subName`, data.subName]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateMenuRotationInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        multiLanguages: multiLanguages,
        precedence: data.precedence ? data.precedence : original.precedence,

        startDate: data.startDate ? data.startDate : original.startDate,
        endDate: data.endDate ? data.endDate : original.endDate,

        _version: original._version,
      };

      const menuRotation: any = await API.graphql<GraphQLQuery<MenuRotation>>({
        query: updateMenuRotation,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));
      return menuRotation.data.updateMenuRotation;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: any) {
    let original = await get(params);
    const updateInput: UpdateMenuRotationInput = {
      id: original.id,
      deleted: "1",
      _version: original._version,
    };

    const menuRotation: any = await API.graphql<GraphQLQuery<MenuRotation>>({
      query: updateMenuRotation,
      variables: { input: updateInput },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });

    showConfirm(`${singleName} has been moved to trash successfully`);

    for (let i = 0; i < paginationListing.length; i++) {
      if (paginationListing[i].id === original.id) {
        paginationListing.splice(i, 1);
        dispatch(setPagination(paginationListing));
        break;
      }
    }
  }

  async function bulkTrash(params: any) {
    const { ids, listing } = params;
    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: MenuRotation[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "startDate",
      numeric: false,
      disablePadding: false,
      label: "Start Date",
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "End Date",
    },

    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
  ];
  const dataCells: readonly string[] = [
    "name",
    "precedence",
    "startDate",
    "endDate",
  ];

  const api: any = {};

  api[`${listingName}Model`] = MenuRotation as any;
  api[`${listingName}CreateSubscription`] = onCreateMenuRotation;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByActiveMenuRotation`] =getActiveMenuRotation;

  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}ChangeListing`] = (listing: MenuRotation[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: MenuRotation[]) =>
    dispatch(setAllListing(listing));
  api[`${listingName}ChangeSelected`] = (menuRotationID: string) =>
    dispatch(setSelected(menuRotationID));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  return api;
};

export default useMenuRotation;
